<template>
	<div class="outer-box" :style="{ backgroundImage: bigImg }" :class="{ en: internationalKey === 'en-US' }">
		<div class="inner-box">
			<div class="inner-box_left" :style="{ backgroundImage: smallImg }"></div>
			<div class="inner-box_right">
				<!-- 国际化 -->
				<div class="login-international" v-if="isI18nFlag" @mouseenter="setUpLanguageEnter()" @mouseleave="setUpLanguageLeave()">
					<span class="fl">
						{{ $t(internationalName) }}
					</span>
					<img src="../../../assets/images/common/down.png" alt="" class="international-img" />
					<ul v-show="setUpLanguageShow" @mouseenter="setUpLanguageEnter()" @mouseleave="setUpLanguageLeave()">
						<li v-for="item in international" :key="item.key" @click="selectChange(item.key, item.val)">
							<span>{{ $t(item.val) }}</span>
						</li>
					</ul>
				</div>
				<!-- 登录表单 -->
				<!-- <template v-if="showLoginForm"> -->
				<template>
					<div class="login">
						<ul class="tabs">
							<li
								@click="setActiveName('password')"
								:class="{
									highlight: activeName === 'password',
								}"
							>
								{{ $t('WDNPC_WEB_PASSWORD_LOGON', { text: '密码登录' }) }}
							</li>
							<li
								@click="setActiveName('code')"
								:class="{
									highlight: activeName === 'code',
								}"
							>
								{{ $t('WDNPC_WEB_VERIFICATION_CODE_LOGIN', { text: '验证码登录' }) }}
							</li>
						</ul>
						<el-form class="form" ref="form" :rules="rules" :model="formData">
							<el-form-item prop="account">
								<el-input
									class="phoneInput"
									:placeholder="$t('WDNPC_WEB_ENTER_A_LOGIN_ACCOUNT', { text: '请输入登录账号' })"
									v-model.trim="formData.account"
									autocomplete="off"
									key="account"
									clearable
								>
									<el-select
										v-if="isI18nFlag"
										v-model="code"
                                        @change="handleSelectChange"
										slot="prepend"
										style="width: 130px"
										class="phonePrepend"
										:placeholder="$t('WDNPC_WEB_PLEASE_SELECT', { text: '请选择' })"
									>
										<el-option
											:label="internationalKey === 'zh-CN' ? item.cn + ' +' + item.mobile_prefix : item.en + ' +' + item.mobile_prefix"
											:value="item.code"
											v-for="item in contryList"
											:key="item.code"
										></el-option>
									</el-select>
								</el-input>
							</el-form-item>
							<el-form-item prop="password" v-if="activeName === 'password'">
								<el-input
									v-model.trim="formData.password"
									@keyup.enter="onSubmit"
									autocomplete="new-password"
									clearable
									show-password
									type="password"
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD', { text: '请输入登录密码' })"
									key="password"
								></el-input>
							</el-form-item>
							<el-form-item prop="authCode" v-else>
								<el-input
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
									v-model.trim="formData.authCode"
									@keyup.enter="onSubmit"
								>
									<template v-slot:append>
										<el-button @click="sendCode" round>{{
											0 >= Countdown ? $t('WDNPC_WEB_SEND_VERIFICATION_CODE', { text: '发送验证码' }) : Countdown + 's'
										}}</el-button>
									</template>
								</el-input>
							</el-form-item>
						</el-form>
						<div class="btn">
							<el-button class="loginbtn" size="small" @click="onSubmit">{{ $t('WDNPC_WEB_LOGIN', { text: '登录' }) }}</el-button>
							<div v-show="activeName === 'password'">
								<router-link :to="{ path: '/forget_new' }" style="color: #5f6368">{{
									$t('WDNPC_WEB_FORGOT_PASSWORD', { text: '忘记密码？' })
								}}</router-link>
							</div>
						</div>
						<div class="read">
							<el-checkbox v-model="hasreaded">{{ $t('WDNPC_WEB_I_HAVE_READ_AND_AGREE', { text: '我已阅读并同意' }) }}</el-checkbox>
							<div class="read_right">
								<router-link class="agreement" :to="{ path: '/userAgreement' }" target="_blank">{{
									$t('WDNPC_WEB_USER_AGREEMENT', { text: '用户协议' })
								}}</router-link>
								<span style="color: #666">{{ $t('WDNPC_WEB_AND', { text: '及' }) }}</span>
								<router-link class="agreement" :to="{ path: '/privacyPolicy' }" target="_blank">{{
									$t('WDNPC_WEB_LEGAL_STATEMENT_AND_PRIVACY_POLICY', { text: '法律声明及隐私政策' })
								}}</router-link>
							</div>
						</div>
						<!-- <div class="footerTip" v-if="canRegister"> -->
						<div class="footerTip">
							<span class="tip">{{ $t('WDNPC_WEB_NO_ACCOUNT_YET', { text: '还没账号？' }) }}</span>
							<router-link :to="{ path: '/register_new' }" class="view">{{ $t('WDNPC_WEB_REGISTER_NOW', { text: '立即注册' }) }} ></router-link>
						</div>
					</div>
				</template>
				<!-- 选择租户 -->
				<!-- <template v-else-if="showOrgan"> -->
				<template v-if="showOrgan">
					<Organ @handleClose="handleChangeShow" @handleError="handleOrganError" />
				</template>
				<!-- 选择身份 -->
				<template v-else-if="showIdentity">
					<Identity @handleClose="handleShowIdentity" @handleError="handleIdentityError" />
				</template>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import Organ from './components/organ'
import Identity from './components/identity'
import md5 from 'js-md5'
import { isPhone, isEmail } from '@/utils/validator'
import contryList from '../../../utils/country'
import getLanguageData from '../../../locale/locale'
export default {
	name: 'login',
	data() {
		return {
			showLoginForm: true,
			showOrgan: false,
			showIdentity: false,
			smallImg: '',
			bigImg: '',
			canRegister: false,
			activeName: 'password',
			CountdownInterval: null,
			Countdown: 0,

			formData: {
				extType: null, //1:手机号，2:邮箱，3:用户名，4:身份证
				type: 1, //1:密码登录，2:验证码登录
				phone: null,
				email: null,
				idCardNo: null,
				username: null,
				account: null,
				password: null,
				authCode: null,
			},
			hasreaded: false,
			accountType: {
				extType: 1, //1:手机号，2:邮箱，3:用户名，4:身份证
				type: 'phone',
				reg: isPhone,
			},
			mobile_prefix: '86',
            code:'156',
			countryList: [],
			isI18nFlag: false, //判断是否是国际化项目
			internationalName: '中文',
			internationalKey: 'zh-CN',
			setUpLanguageTimeout: null,
            setUpLanguageShow: false,
            rules:{
				account: [
					// {
					// 	message: this.$t('WDNPC_WEB_ENTER_A_LOGIN_ACCOUNT', { text: '请输入登录账号' }),
					// 	required: true,
					// 	trigger: 'blur',
					// },
					{
						validator: this.accountValidator,
						trigger: 'blur',
					},
				],
				password: [
					// {
					// 	required: true,
					// 	message: this.$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD', { text: '请输入登录密码' }),
					// 	trigger: 'blur',
                    // },
                    {
						validator: this.passwordValidator,
						trigger: 'blur',
					},
				],
				authCode: [
					// {
					// 	required: true,
					// 	message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
					// 	trigger: 'blur',
					// },
					// {
					// 	min: 6,
					// 	max: 6,
					// 	message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
					// 	trigger: 'blur',
                    // },
                    {
						validator: this.authcodeValidator,
						trigger: 'blur',
					},
				],
			}
		}
	},
	components: {
		Organ,
		Identity,
	},
	created() {
		this.contryList = contryList
		this.initConfig()
	},
	computed: {
		theme() {
			return this.$store.state.theme
		},
		sendCodeBtnStyle() {
			let background = '#316fff'
			return {
				background: 0 >= this.Countdown ? background : '#BFC8D9',
				border: 'none',
			}
		},
		frontendConfig() {
			return this.$store.state.dynamic.frontendConfig
		},
		// rules() {
		// 	return {
		// 		account: [
		// 			{
		// 				message: this.$t('WDNPC_WEB_ENTER_A_LOGIN_ACCOUNT', { text: '请输入登录账号' }),
		// 				required: true,
		// 				trigger: 'blur',
		// 			},
		// 			{
		// 				validator: this.accountValidator,
		// 				trigger: 'blur',
		// 			},
		// 		],
		// 		password: [
		// 			{
		// 				required: true,
		// 				message: this.$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD', { text: '请输入登录密码' }),
		// 				trigger: 'blur',
		// 			},
		// 		],
		// 		authCode: [
		// 			{
		// 				required: true,
		// 				message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
		// 				trigger: 'blur',
		// 			},
		// 			{
		// 				min: 6,
		// 				max: 6,
		// 				message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
		// 				trigger: 'blur',
		// 			},
		// 		],
		// 	}
		// },
		international() {
			return [
				{ key: 'zh-CN', val: '中文' }, //中文
                { key: 'en-US', val: 'English' }, //英语
                { key: "fr-FR", val: 'Français' }//法语
			]
        },
        saasUcode() {
			let pageSetData = this.$store.state.dynamic.pageSetData
			return pageSetData && pageSetData.solutionInfo && pageSetData.solutionInfo.saasUcode
		},
	},
	mounted() {
        if (localStorage.getItem('current_language')) {
			let innertational = this.international.find((item) => item.key == localStorage.getItem('current_language'))
			if (innertational) {
				this.internationalName = innertational.val
                this.internationalKey = innertational.key
                document.getElementsByTagName('html')[0].setAttribute('lang', this.internationalKey)
			}
		}
    },
	methods: {
        handleSelectChange(val){
            this.mobile_prefix = this.contryList.find(item => item.code === val).mobile_prefix
        },
		// 切换语言
		setUpLanguageEnter() {
			clearTimeout(this.setUpLanguageTimeout)
			this.setUpLanguageShow = true
		},
		setUpLanguageLeave() {
			this.setUpLanguageTimeout = setTimeout(() => {
				this.setUpLanguageShow = false
			}, 300)
		},
		async selectChange(key, value) {
            this.$refs.form.clearValidate()
			console.log(key, value, 'key', 'value', this.$t(value))
            localStorage.setItem('current_language', key)
            await getLanguageData()
			this.$i18n.locale = key
			this.internationalKey = key
            this.internationalName = value
            document.getElementsByTagName('html')[0].setAttribute('lang', this.internationalKey)
			this.setUpLanguageTimeout = setTimeout(() => {
				this.setUpLanguageShow = false
            }, 100)
		},
		accountValidator(rule, value, callback) {
			if (!value) {
				callback(new Error(this.$t('WDNPC_WEB_ENTER_A_LOGIN_ACCOUNT', { text: '请输入登录账号' })))
            } else if(isPhone.test(value) || isEmail.test(value)) {
                callback()
            } else {
				callback(new Error(this.$t('WDNPC_WEB_ENTER_THE_CORRECT_LOGON_ACCOUNT', { text: '请输入正确的登录账号' })))
			}
        },
        passwordValidator(rule, value, callback){
            if (!value) {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD', { text: '请输入登录密码' })))
			} else {
				callback()
			}
        },
        authcodeValidator(rule, value, callback){
            if (!value) {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })))
			} else if(value.length != 6) {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' })))
			} else {
                callback()
            }
        },
		handleChangeShow() {
			this.showOrgan = false
			this.showIdentity = true
		},
		handleShowIdentity() {
			this.showIdentity = false
			this.findMyBranch()
		},
		handleOrganError() {
			this.showOrgan = false
		},
		handleIdentityError() {
			this.showIdentity = false
		},
		setActiveName(type) {
			this.activeName = type
			if (type === 'password') {
				this.formData.type = 1
			} else {
				this.formData.type = 2
			}
			this.$refs.form.clearValidate()
		},
		initConfig() {
			if (this.frontendConfig.length > 0) {
				localStorage.setItem('configurationArr', JSON.stringify(this.frontendConfig))
				this.frontendConfig.forEach((item) => {
					switch (item.key) {
						case 'is_i18n':
							if (item.value === 'true') {
								this.isI18nFlag = true
							} else {
								this.isI18nFlag = false
							}
							localStorage.setItem('is_i18n', item.value)
							break
						case 'custom_login_background_img':
							if (item.value) {
								this.bigImg = item.value
								localStorage.setItem('custom_login_background_img', item.value)
							}
							break
						case 'custom_login_img':
							if (item.value) {
								this.smallImg = item.value
								localStorage.setItem('custom_login_img', item.value)
							}
							break
						case 'is_open_register':
							this.canRegister = item.value === 'true' ? true : false
							break
						default:
							break
					}
				})
			}
		},
		async onSubmit() {
			//  this.$router.push({
			// 		path: '/learning',
            // 	})
			this.$refs.form.validate((valid) => {
				if (valid) {
					if (!this.hasreaded) {
						this.$message.warning(this.$t('WDNPC_WEB_PLEASE_SELECT_USER_AGREEMENT', { text: '请勾选用户协议' }))
						return false
					}
					this.toLogin()
				} else {
					return false
				}
			})
		},
		async toLogin() {
			//处理入参字段
			let data = this.handleParams()
			this.$api.account_new.login(data).then((res) => {
				if (res.success) {
					localStorage.setItem('token', res.data)
					localStorage.setItem('mobile_prefix', this.mobile_prefix)
					this.showLoginForm = false
                    // 根据域名选择租户
                    this.setUserOrgan()
				}
			})
		},
		// 登录参数处理
		handleParams() {
			let hashPassword = ''
			if (this.formData.password) {
				hashPassword = md5(this.formData.password).toLowerCase()
			}
			let data = {
				extType: null, //1:手机号，2:邮箱，3:用户名，4:身份证
				type: this.formData.type, //1:密码登录，2:验证码登录
				password: hashPassword,
				authCode: this.formData.authCode,
				areaCode: this.mobile_prefix,
			}
			// 判断登录账号类型
			let accountTypeArr = [
				{
					extType: 1,
					type: 'phone',
					reg: isPhone,
				},
				{
					extType: 2,
					type: 'email',
					reg: isEmail,
				},
			]
			let accountType = accountTypeArr.find((item) => item.reg.test(this.formData.account))
			this.accountType = accountType
			if (!accountType) {
				//用户名登录
				data.extType = 3
				data.username = this.formData.account
			} else {
				data.extType = accountType.extType
				data[accountType.type] = this.formData.account
			}
			return data
        },
        setUserOrgan() {
			this.$api.account_new.switchSaas(this.saasUcode).then((res) => {
				if (res.success) {
					this.showIdentity = true
				}else{
                    // // 临时放开
                    // this.showOrgan = true
                }
			})
		},
		findMyBranch() {
			this.$api.branch_new.findMyBranch().then((res) => {
				if (res.data && res.data[0]) {
					localStorage.setItem('branchName', res.data[0].name)
					localStorage.setItem('branchUcode', res.data[0].ucode)
					localStorage.setItem('branchList', JSON.stringify(res.data))
					this.switchBranch(res.data[0].ucode)
				}
			})
		},
		switchBranch(branchUcode) {
			this.$api.account_new.switchBranch(branchUcode).then((res) => {
				if (res.success) {
					this.getUserInfo()
				}
			})
		},
		// 获取用户信息
		getUserInfo() {
			this.$api.user_new.getUserInfo().then((res) => {
				if (res.success) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
					this.handleSkip()
				}
			})
		},
		// 成功登录
		handleSkip() {
			if (this.$route.query.name) {
				this.$router.push({
					path: this.$route.query.name + window.location.search,
				})
			} else {
				this.$router.push({
					path: '/learning',
				})
			}
		},
		// 发送验证码
		sendCode() {
			if (this.Countdown > 0) return
			// 校验手机号、邮箱
			this.$refs.form.validateField('account', (err) => {
				if (!err) {
					this.formData.authCode = null
					this.Countdown = 60
					this.setCountdown()

					// 判断登录账号类型
					let accountTypeArr = [
						{
							extType: 1,
							type: 'phone',
							reg: isPhone,
						},
						{
							extType: 2,
							type: 'email',
							reg: isEmail,
						},
					]
					let accountType = accountTypeArr.find((item) => item.reg.test(this.formData.account))

					if (accountType.type === 'phone') {
						this.$api.account_new.getPhoneAuthCode4Login({ phone: this.formData.account, areaCode: this.mobile_prefix })
					} else if (accountType.type === 'email') {
						this.$api.account_new.getMailAuthCode4Login({ mail: this.formData.account })
					}
				}
			})
		},
		// 验证码定时器
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
	},
}
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
@import './css/login';
@import './css/login_en';
    .login {
        width 397px
        position absolute
        z-index 1
    }
</style>
