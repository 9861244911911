<template>
	<div v-show="show" class="identity login-box1">
		<div class="login-box1-title">{{ $t('WDNPC_WEB_WELCOME_TO_LOGIN', { text: '欢迎登录' }) }}!</div>
		<div class="login-box1-tip" v-if="identityList && identityList.length">
			{{ $t('WDNPC_WEB_YOUR_ACCOUNT_HAS_MULTIPLE_IDENTITIES_PLEASE_SELECT', { text: '您的账号拥有多个身份，请选择要登录的身份' }) }}
		</div>

		<ul class="login-box1-list" v-if="identityList && identityList.length">
			<li v-for="(item, index) in identityList" :key="index" @click="setUseridentity(item)">
				{{ item.name }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'identity',
	data() {
		return {
			identityList: [],
			show: false,
		}
	},
	mounted() {
		this.findMyIdentity()
	},
	methods: {
		findMyIdentity() {
			this.$api.userIdentityX_new.findMyIdentity().then((res) => {
				if (res.data && res.data.length) {
					if (res.data.length === 1) {
						this.setUseridentity(res.data[0])
					} else {
						let identityObj = res.data.find((item) => item.identity == 200)
						if (identityObj) {
							//默认选择学员身份
							this.setUseridentity(identityObj)
						} else {
							this.show = true
						}
					}
					this.identityList = res.data
				} else {
					this.$emit('handleError')
				}
			})
		},

		async setUseridentity(item) {
			this.$api.account_new.switchIdentity(item.identity).then((res) => {
				if (res.success) {
					localStorage.setItem('identityName', item.name)
					localStorage.setItem('identity', item.identity)
					this.$emit('handleClose')
				} else {
					this.$emit('handleError')
				}
			})
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/selectDiv.styl';
.identity
    position absolute
    z-index 3
</style>
