<template>
	<div v-show="show" class="organ login-box1">
		<div class="login-box1-title">{{$t('WDNPC_WEB_WELCOME_TO_LOGIN',{text:'欢迎登录'})}}!</div>
		<div class="login-box1-tip" v-if="organList && organList.length">
			{{$t('WDNPC_WEB_YOUR_ACCOUNT_BELONGS_TO_MULTIPLE_ORGANIZATIONS_PLEASE',{text:'您的账号属于多个组织，请选择要登录的组织'})}}
		</div>
		<div class="login-box1-tip" v-else>{{$t('WDNPC_WEB_YOU_DO_NOT_HAVE_AN_ORGANIZATION_YET',{text:'你还没有组织，请联系管理员添加权限。'})}}</div>
		<ul class="login-box1-list" v-if="organList && organList.length">
			<li v-for="(item, index) in organList" :key="index" @click="setUserOrgan(item)">
				{{ item.name }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'organ',
	data() {
		return {
			organList: [],
			show: false,
		}
	},
	mounted() {
		this.getFindOrganList()
	},
	methods: {
		getFindOrganList() {
			this.$api.saas_new.findMySaas().then((res) => {
				if (res.data && res.data.length) {
					if (res.data.length === 1) {
                        this.setUserOrgan(res.data[0])
					} else {
						this.show = true
					}
					this.organList = res.data
				}else{
                    this.$emit('handleError')
                }
			})
		},

		async setUserOrgan(item) {
			this.$api.account_new.switchSaas(item.ucode).then((res) => {
				if (res.success) {
					localStorage.setItem('saasName', item.name)
					localStorage.setItem('saasUcode', item.ucode)
					this.$emit('handleClose')
				}else{
                    this.$emit('handleError')
                }
			})
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../css/selectDiv.styl';
.organ {
    position absolute
    z-index 2
}
</style>
